<template>
  <dl class="row px-3 px-sm-5 pt-3 mb-0">
    <dt class="no-colon d-print-none w-xs-100">Aksiyon Seçiniz</dt>
    <dd class="single d-print-none w-xs-100 pl-1 pl-sm-0">
      <div class="form-group">
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            name="idea-action"
            id="cbx-submit"
            data-target="#tab-form-approve"
            value="approve"
            v-model="evaluate"
          />
          <label class="form-check-label" for="cbx-submit">Onayla</label>
        </div>

        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            name="idea-action"
            id="cbx-return"
            data-target="#tab-form-return"
            value="return"
            v-model="evaluate"
          />
          <label class="form-check-label" for="cbx-return">Geri Gönder</label>
        </div>

        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            name="idea-action"
            id="cbx-reject"
            data-target="#tab-form-decline"
            value="decline"
            v-model="evaluate"
          />
          <label class="form-check-label" for="cbx-reject">Reddet</label>
        </div>

        <div v-if="evaluate == 'approve'" id="tab-form-approve" style="">
          <form
            action="/Admin/Update/c5dd0e00-a1d9-eb11-bacb-000d3addce7f?updateType=1&amp;status=Distributer"
            id="update-form"
            method="post"
          >
            <div class="form-group icon-folder-alt icon-down">
              <select
                class="form-control"
                id="searchFilterValueCurrent"
                name="ValueCurrent"
                title="Tümü"
                placeholder="Tümü"
                v-model="valueCurrentId"
              >
                <option value="">İlgili Alan Seçiniz</option>
                <optgroup
                  v-for="item in currenValuelist"
                  :key="item.Name"
                  :label="item.Name"
                >
                  <option
                    v-for="company in item.ValueCurrentDTOs"
                    :key="company.Id"
                    :value="company.Id"
                  >
                    {{ company.Name }}
                  </option>
                </optgroup>
              </select>
            </div>
            <div
              id="drpValueCurrentError"
              class="form-group invalid-feedback"
              :style="[
                requiredField.includes('valueCurrentId')
                  ? { display: 'block' }
                  : '',
              ]"
            >
              Bu alan boş geçilemez!
            </div>
            <div class="form-group decider-multiselect-wrapper">
              <multiselect
                v-model="teamLeader"
                tag-placeholder="Add this as new tag"
                placeholder="Ekip Lideri Seçiniz"
                label="Name"
                track-by="Id"
                :options="optionTeamLeader"
                :searchable="true"
                style="padding: 0"
                selectLabel="Seçmek için tıklayınız"
                selectedLabel="Seçildi"
                deselectLabel="Silmek için tıklayınız"
              >
              </multiselect>
            </div>
            <div
              id="teamLeaderError"
              class="form-group invalid-feedback"
              :style="[
                requiredField.includes('teamLeader')
                  ? { display: 'block' }
                  : '',
              ]"
            >
              Bu alan boş geçilemez!
            </div>
            <div class="form-group">
              <textarea
                class="form-control short"
                cols="20"
                id="textSuggestionDeciderNote"
                name="SuggestionDeciderNote"
                placeholder="Fikir Değerlendirme Açıklaması..."
                rows="2"
                validationelement="1"
                v-model="suggestionDeciderNote"
              ></textarea>
            </div>
            <div
              id="textSuggestionDeciderNoteError"
              class="form-group invalid-feedback"
              :style="[
                requiredField.includes('suggestionDeciderNote')
                  ? { display: 'block' }
                  : '',
              ]"
            >
              Bu alan boş geçilemez!
            </div>
            <div class="insider-form-button pb-2 pt-3">
              <button
                type="button"
                class="btn-innovation-radius bg-orange Roboto-Bold py-2 px-4 mr-3"
                data-dismiss="modal"
                aria-label="Close"
                @click="removeValue"
              >
                VAZGEÇ
              </button>
              <button
                :disabled="isBtnDisabled"
                type="button"
                id="btnSendUpdate"
                @click="sendUpdate"
                class="btn-innovation-radius bg-blue-1 Roboto-Bold py-2 px-4"
              >
                GÖNDER
              </button>
            </div>
          </form>
        </div>

        <div id="tab-form-return" v-if="evaluate == 'return'">
          <form
            action="/Admin/Update/5b677e5f-a0d9-eb11-bacb-000d3addce7f?isReturn=True&amp;status=Distributer"
            method="post"
          >
            <div class="form-group">
              <textarea
                class="form-control"
                cols="20"
                id="ReasonForReturn"
                name="ReasonForReturn"
                placeholder="Geri gönderme nedenini giriniz"
                required="required"
                rows="2"
                v-model="reasonForReturn"
              ></textarea>
            </div>
            <div
              class="form-group invalid-feedback"
              :style="[isReasonForReturn ? { display: 'block' } : '']"
            >
              Bu alan boş geçilemez!
            </div>
            <div class="insider-form-button pb-2 pt-3">
              <button
                type="button"
                class="btn-innovation-radius bg-orange Roboto-Bold py-2 px-4 mr-3"
                data-dismiss="modal"
                aria-label="Close"
                @click="clearReturnValue"
              >
                VAZGEÇ
              </button>
              <button
                type="button"
                class="btn-innovation-radius bg-blue-1 Roboto-Bold py-2 px-4"
                @click="sendReasonForReturn"
                :disabled="isBtnDisabled"
              >
                DEĞERLENDİR
              </button>
            </div>
          </form>
        </div>

        <div v-if="evaluate == 'decline'" id="tab-form-decline" style="">
          <form
            action="/Admin/Update/c5dd0e00-a1d9-eb11-bacb-000d3addce7f?updateType=2&amp;status=Reject"
            method="post"
          >
            <div class="form-group icon-note">
              <select
                class="form-control"
                id="CancelReason"
                name="CancelReason"
                required="required"
                v-model="cancelReason"
              >
                <option value="">Ret Nedeni Seçiniz</option>
                <option value="1">Hata/sorun Bildirimi</option>
                <option value="2">Daha Önce İletilmiş Fikir</option>
                <option value="3">
                  Fikir Platformu Kapsamında Değerlendirilmemektedir
                </option>
                <option value="4">Projelendirilen/Mevcut Uygulama</option>
                <option value="5">Product Board Kararı</option>
                <option value="6">Fikir Sahibi Çekildi</option>
                <option value="7">Fayda-Maliyet Oranı Düşük</option>
                <option value="8">
                  Teknik/İşlevsel/Süreçsel Olarak Uygun Değil
                </option>
              </select>
            </div>
            <div
              id="cancelReasonError"
              class="form-group invalid-feedback"
              :style="[
                requiredField.includes('cancelReason')
                  ? { display: 'block' }
                  : '',
              ]"
            >
              Bu alan boş geçilemez!
            </div>
            <div class="form-group">
              <textarea
                class="form-control short"
                cols="20"
                id="txtConfirmNote"
                name="CancelDescription"
                placeholder="Ret Açıklaması..."
                required="required"
                rows="2"
                v-model="cancelDescription"
              ></textarea>
            </div>
            <div
              id="cancelDescriptionError"
              class="form-group invalid-feedback"
              :style="[
                requiredField.includes('cancelDescription')
                  ? { display: 'block' }
                  : '',
              ]"
            >
              Bu alan boş geçilemez!
            </div>
            <div class="insider-form-button pb-2 pt-3">
              <button
                type="button"
                class="btn-innovation-radius bg-orange Roboto-Bold py-2 px-4 mr-3"
                data-dismiss="modal"
                aria-label="Close"
                @click="clearValue"
              >
                VAZGEÇ
              </button>
              <button
                type="button"
                class="btn-innovation-radius bg-blue-1 Roboto-Bold py-2 px-4"
                @click="declineSubmit"
                :disabled="isBtnDisabled"
              >
                GÖNDER
              </button>
            </div>
          </form>
        </div>
      </div>
    </dd>
  </dl>
</template>

<script>
import { mapGetters } from "vuex";

import Multiselect from "vue-multiselect";
import "../../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css";
export default {
  name: "Decider",
  data() {
    return {
      requiredField: [],
      isBtnDisabled: false,
      evaluate: "",
      suggestionDeciderNote: null,
      valueCurrentId: "",
      suggestionSubject: "",
      suggestionEvaluator: "",
      currenValuelist: [],
      cancelReason: "",
      cancelDescription: "",
      reasonForReturn: "",
      isReasonForReturn: false,
      teamLeader: "",
      optionTeamLeader: [],
    };
  },
  components: {
    Multiselect,
  },
  props: ["detailData"],
  computed: {
    ...mapGetters({
      user: "user/user",
    }),
    gettersValueCurrents() {
      return this.$store.getters["suggestion/getValueCurrents"];
    },
    getUsers() {
      return this.$store.getters["user/getUsers"];
    },
  },
  watch: {
    getUsers: {
      handler: function (val) {
        // Return the object that changed
        val.forEach((element) => {
          this.setUsers(element);
        });
      },
      deep: true,
    },
  },
  async created() {
    if (!this.getUsers) {
      await this.$store.dispatch("user/getUsers");
    } else {
      this.getUsers.forEach((element) => {
        this.setUsers(element);
      });
    }
    this.suggestionSubject = this.detailData.SuggestionSubjectId;
    this.valueCurrentId = this.detailData.ValueCurrentId;
    this.getValueCurrents();
  },
  methods: {
    setUsers(element) {
      this.optionTeamLeader.push({
        Name: element.FullName + " (" + element.CompanyName + ")",
        Id: element.Id,
      });
    },
    async getValueCurrents() {
      if (!this.gettersValueCurrents) {
        await this.$store.dispatch("suggestion/getValueCurrentsData");
      }
      this.currenValuelist = this.gettersValueCurrents;
    },
    removeValue() {
      this.suggestionDeciderNote = null;
      this.valueCurrentId = "";
      this.suggestionSubject = "";
      this.$emit("on-remove-value");
    },
    async sendUpdate() {
      this.requiredField = [];
      if (
        this.suggestionDeciderNote != null &&
        this.valueCurrentId != "" &&
        this.teamLeader != ""
      ) {
        this.isBtnDisabled = true;
        this.$emit("send-update", {
          suggestionDeciderNote: this.suggestionDeciderNote,
          valueCurrentId: this.valueCurrentId,
          suggestionSubject: this.suggestionSubject,
          teamLeaderId: this.teamLeader.Id,
        });
        this.isBtnDisabled = false;
      } else {
        if (this.suggestionDeciderNote == null)
          this.requiredField.push("suggestionDeciderNote");
        if (this.valueCurrentId == "")
          this.requiredField.push("valueCurrentId");
        if (this.teamLeader == "") this.requiredField.push("teamLeader");
      }
    },
    clearValue() {
      this.cancelReason = null;
      this.cancelDescription = null;
      this.$emit("on-clear-value");
    },
    declineSubmit() {
      this.requiredField = [];
      if (this.cancelReason && this.cancelDescription) {
        this.isBtnDisabled = true;
        this.$emit("decline-submit", {
          cancelReason: this.cancelReason,
          cancelDescription: this.cancelDescription,
        });
        this.isBtnDisabled = false;
      } else {
        if (this.cancelReason == "") this.requiredField.push("cancelReason");
        if (this.cancelDescription == "")
          this.requiredField.push("cancelDescription");
      }
    },
    sendReasonForReturn() {
      if (this.reasonForReturn) {
        this.isBtnDisabled = true;
        this.$emit("send-reason-return", {
          reasonForReturn: this.reasonForReturn,
        });
        this.isBtnDisabled = false;
        this.isReasonForReturn = false;
      } else {
        this.isReasonForReturn = true;
      }
    },
    clearReturnValue() {
      this.reasonForReturn = null;
      this.$emit("on-clear-return-value");
    },
  },
};
</script>

<style>
.decider-multiselect-wrapper .multiselect .multiselect__tags > input {
  border: none !important;
}
</style>
