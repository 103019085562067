<template>
  <div class="row py-3 idea-header">
    <h1 class="col-12 col-md-6 pl-0">
      Bekleyen Fikirler <span>({{ totalIdea }})</span>
    </h1>
    <div class="col-12 col-md-6 p-0 text-right">
      <div class="sort-table icon-down mb-1 mb-sm-0" style="z-index: 0">
        <span class="vertical-abs-middle">Sırala:</span>
        <select id="drpOrderType" @change="orderData">
          <option value="ascending">Tarihe göre önce</option>
          <option value="descending" selected="">Tarihe göre sonra</option>
        </select>
      </div>
      <button
        id="btn-filter"
        class="ml-3"
        @click="suggestionToggle = !suggestionToggle"
      >
        Filtrele
        <svg width="20px" height="21px">
          <image
            x="0px"
            y="0px"
            width="20px"
            height="21px"
            xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAMAAABxCz6aAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAA9lBMVEWEkq////+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq+Ekq////8RycwEAAAAUXRSTlMAAInIzMmV0sNWXVWy4z/hJhPbgs4JA72ZBJd91gsn6k877DdlFtV+qKyWuxLga1LpIUspG2COBq4K3zUZt2rZtmdT2AFIuF45tMulNGbBbh6l/VfOAAAAAWJLR0QB/wIt3gAAAAd0SU1FB+IMFBY6Oe/UtFMAAACuSURBVBjTpdDHDoJQEIXhc60oYkHFLnaxK/aOXezv/zRCCBHu1n8zyZdZTAYOp4vK7YGX8fltsQEOwVAY1kiEZ4FoLG5FIZEkQIpJWzGTzWmIvFj4WbFUJjqiwlVNi9fqxEBIDRObLcHEdqdrWK8/ICZCFof6GHFj/BATZqpdM5NgxflCBpbcyoZYbwB2CzsqO2B/OFKb/+OJp/B8wVW90agq/P1BnfR8vT/G57W+P/YUekuP1jIAAAAASUVORK5CYII="
          ></image>
        </svg>
      </button>
    </div>
    <transition name="slide-down">
      <div
        v-if="suggestionToggle"
        id="searchContainer"
        class="col-12 row m-0 p-0"
        style=""
      >
        <div class="container mb-3 mw-100">
          <input
            name="__RequestVerificationToken"
            type="hidden"
            value="BmixSW_HrIQ5LSASjaujnB1o04QRL_7Tgmmdpbemidsn9Fh3h7cAu9HEmqVvZAAV18Vbbc9hn53MV7p7mq2obYciHEFZMe2sArig60yORQ98jO1ugkHgGGMNeE0Ih1nU9AHdmcSQHkGRK6pIGILTRQ2"
          />
          <div class="form-group col-12 col-md-12 row">
            <div class="col-5 col-md-4">
              <label for="searchKeywordText">Fikir Numarası</label>
              <input
                type="text"
                class="form-control"
                id="searchKeywordText"
                placeholder=""
                v-model="suggestionNumber"
              />
            </div>
            <div
              class="col-6 col-md-4 admin-custom-selectbox"
              :class="{
                'selectbox-padding':
                  createdUserId != '' && createdUserId != null,
              }"
            >
              <label for="searchFilterUser">Kullanıcı</label>
              <multiselect
                v-model="createdUserId"
                tag-placeholder="Add this as new tag"
                placeholder="Tümü"
                label="name"
                track-by="code"
                :options="optionUsers"
                :taggable="true"
                :searchable="true"
                @tag="addTagUser"
                selectLabel="Seçmek için tıklayınız"
                selectedLabel="Seçildi"
                deselectLabel="Silmek için tıklayınız"
              ></multiselect>
            </div>
            <div class="col-6 col-md-4">
              <label for="drpAccount">Fikir Verilen Firmalar</label>
              <select
                class="form-control"
                id="drpAccount"
                name="AccountId"
                title="Tümü"
                placeholder="Tümü"
                v-model="selectedCompany"
              >
                <option
                  v-for="(item, key) in companiesList"
                  :key="key"
                  :value="item.Id"
                >
                  {{ item.Name }}
                </option>
              </select>
            </div>
            <div class="col-5 col-md-4 mt-3">
              <label for="searchKeywordText">Anahtar Kelime</label>
              <input
                type="text"
                class="form-control"
                id="searchKeywordText"
                placeholder=""
                v-model="searchKeyword"
              />
            </div>
          </div>
          <div class="form-group col-12 col-md-12 row">
            <div class="col-6 col-md-4">
              <label for="searchFilterStart">Tarih Aralığı</label>
              <div class="d-flex flex-column flex-md-row">
                <div class="col-12 col-md-6 pl-0 date-container">
                  <input
                    class="datepicker form-control"
                    data-val="true"
                    data-val-date="The field StartDate must be a date."
                    id="searchFilterStart"
                    name="StartDate"
                    type="date"
                    v-model="startDate"
                  />
                </div>
                <div
                  class="col-12 col-md-6 pl-0 pl-md-2 mt-2 mt-md-0 date-container"
                >
                  <input
                    class="datepicker form-control"
                    data-val="true"
                    data-val-date="The field EndDate must be a date."
                    id="searchFilterEnd"
                    name="EndDate"
                    type="date"
                    v-model="endDate"
                  />
                </div>
              </div>
            </div>
            <div
              class="col-6 col-md-4 admin-custom-selectbox"
              :class="{
                'selectbox-tag-padding':
                  suggestionStatuses.length > 0 && suggestionStatuses != null,
              }"
            >
              <label for="searchFilterValueCurrent">Statü</label>
              <multiselect
                v-model="suggestionStatuses"
                tag-placeholder="Add this as new tag"
                placeholder="Statü Seçiniz"
                label="value"
                track-by="id"
                :options="statusList"
                :multiple="true"
                :taggable="true"
                :searchable="true"
                @tag="addTagStatus"
                selectLabel="Seçmek için tıklayınız"
                selectedLabel="Seçildi"
                deselectLabel="Silmek için tıklayınız"
              ></multiselect>
            </div>
            <div class="col-6 col-md-4">
              <label for="searchFilterValueCurrent">Değer Akımı</label>
              <div id="valueCurrentField">
                <select
                  class="form-control"
                  id="searchFilterValueCurrent"
                  name="ValueCurrent"
                  title="Tümü"
                  placeholder="Tümü"
                  v-model="valueCurrentId"
                >
                  <option value="">Tümü</option>
                  <optgroup
                    v-for="item in currenValuelist"
                    :key="item.Name"
                    :label="item.Name"
                  >
                    <option
                      v-for="company in item.ValueCurrentDTOs"
                      :key="company.Id"
                      :value="company.Id"
                    >
                      {{ company.Name }}
                    </option>
                  </optgroup>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer w-100 border-0 justify-content-end">
          <button
            @click="searchFilterRemoveValue"
            type="reset"
            class="btn-innovation-modal bg-orange"
          >
            Sıfırla
          </button>
          <button
            @click="searchFilter"
            type="button"
            class="btn-innovation-modal bg-orange"
          >
            Filtrele <span class="icon-arrow-right pl-2"></span>
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import "../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css";
export default {
  name: "SuggestionHeader",
  data() {
    return {
      suggestionToggle: true,
      statusList: [],
      selectedStatusList: [],
      createdUserId: "",
      optionUsers: [],
      selectedCompany: "",
      valueCurrentId: "",
      currenValuelist: [],
      suggestionStatuses: [],
      startDate: null,
      endDate: null,
      searchKeyword: "",
      suggestionNumber: "",
      companiesList: [],
    };
  },
  components: {
    Multiselect,
  },
  props: {
    location: {
      type: String,
      default: "evaluation-invendo",
    },
    totalIdea: {
      type: Number,
    },
    suggestionTypeId: {
      type: String,
    },
  },
  computed: {
    ...mapGetters({
      user: "user/user",
    }),
    getUsers() {
      return this.$store.getters["user/getUsers"];
    },
    getCompanies() {
      return this.$store.getters["suggestion/companiesData"];
    },
    gettersValueCurrents() {
      return this.$store.getters["suggestion/getValueCurrents"];
    },
    gettersStatusByCustomerRoles() {
      return this.$store.getters["suggestion/statusByCustomerRoles"];
    },    
    gettersStatusByCustomerRolesInvendo() {
      return this.$store.getters["suggestion/statusByCustomerRolesInvendo"];
    },
    gettersStatusByCustomerRolesProductization() {
      return this.$store.getters["suggestion/statusByCustomerRolesProductization"];
    },
    gettersFilterStatusByRoles() {
      return this.$store.getters["suggestion/filterStatusByRoles"];
    },
    statusByRolesInvendo() {
      return this.$store.getters["suggestion/filterStatusByRolesInvendo"];
    },
    statusByRolesProductization() {
      return this.$store.getters["suggestion/filterStatusByRolesProductization"];
    },
  },
  watch: {
    getUsers: {
      handler: function (val) {
        // Return the object that changed
        val.forEach((element) => {
          this.optionUsers.push({
            name: element.FullName + " (" + element.CompanyName + ")",
            code: element.Id,
          });
        });
      },
      deep: true,
    },
  },
  async created() {
    if (!this.getUsers) {
      await this.$store.dispatch("user/getUsers");
    } else {
      this.getUsers.forEach((element) => {
        this.optionUsers.push({
          name: element.FullName + " (" + element.CompanyName + ")",
          code: element.Id,
        });
      });
    }
    if (!this.getCompanies) await this.$store.dispatch("suggestion/setData");
    this.companiesList = [{ Id: "", Name: "Tümü" }, ...this.getCompanies];
    await this.getStatusByCustomerRoles();
    await this.getFilterStatusByRoles();
    await this.getValueCurrents();
    await this.equalData();
  },
  methods: {
    equateSelectedCompany() {
      if (
        this.getCompanies &&
        this.getCompanies.length == 2 &&
        this.getCompanies[0].Id == 2 &&
        this.getCompanies[1].Id == 7
      ) {
        this.selectedCompany = "";
      } else {
        this.selectedCompany = this.user.Data.CompanyId;
      }
    },
    equalData() {
      this.equateSelectedCompany();
    },
    orderData(event) {
      this.$emit("order-data", event.srcElement.value);
    },
    addTagUser(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.optionUsers.push(tag);
      this.createdUserId = tag;
    },
    addTagStatus(newTag) {
      const tag = {
        value: newTag,
        id: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.statusList.push(tag);
      this.suggestionStatuses.push(tag);
    },
    async getValueCurrents() {
      if (this.gettersValueCurrents.length == 0) {
        await this.$store.dispatch("suggestion/getValueCurrentsData");
      }
      this.currenValuelist = this.gettersValueCurrents;
    },
    async getStatusByCustomerRoles() {
      if (this.suggestionTypeId == "1") {
        if (this.gettersStatusByCustomerRoles.length == 0) {
          await this.$store.dispatch("suggestion/getStatusByCustomerRoles", {
            suggestionTypeId: this.suggestionTypeId,
            companyId: this.user.Data.CompanyId,
            loginUserId: this.user.Data.UserId,
          });
        }
        await this.gettersStatusByCustomerRoles.forEach(async (element) => {
          this.selectedStatusList.push({
            id: element.StatusId,
            value: element.StatusName,
            selected: element.Selected
          });
        });
      } else if (this.suggestionTypeId == "4") {
        if (this.gettersStatusByCustomerRolesInvendo.length == 0) {
          await this.$store.dispatch("suggestion/getStatusByCustomerRolesInvendo", {
            suggestionTypeId: this.suggestionTypeId,
            companyId: this.user.Data.CompanyId,
            loginUserId: this.user.Data.UserId,
          });
        }
        await this.gettersStatusByCustomerRolesInvendo.forEach(async (element) => {
          this.selectedStatusList.push({
            id: element.StatusId,
            value: element.StatusName,
            selected: element.Selected
          });
        });
      } else if (this.suggestionTypeId == "3") {
        if (this.gettersStatusByCustomerRolesProductization.length == 0) {
          await this.$store.dispatch(
            "suggestion/getStatusByCustomerRolesProductization",
            {
              suggestionTypeId: this.suggestionTypeId,
              companyId: this.user.Data.CompanyId,
              loginUserId: this.user.Data.UserId,
            }
          );
        }
        await this.gettersStatusByCustomerRolesProductization.forEach(async (element) => {
          this.selectedStatusList.push({
            id: element.StatusId,
            value: element.StatusName,
            selected: element.Selected
          });
        });
      }
      this.suggestionStatuses = this.selectedStatusList.filter(s => s.selected == true)
    },    
    async getFilterStatusByRoles() {
      if (this.suggestionTypeId == "1") {
        if (this.gettersFilterStatusByRoles.length == 0) {
          await this.$store.dispatch("suggestion/getFilterStatusByRoles", {
            suggestionTypeId: this.suggestionTypeId,
            companyId: this.user.Data.CompanyId,
            loginUserId: this.user.Data.UserId,
          });
        }
        await this.gettersFilterStatusByRoles.forEach(async (element) => {
          this.statusList.push({
            id: element.StatusId,
            value: element.StatusName,
            selected: element.Selected
          });
        });
      } else if (this.suggestionTypeId == "4") {
        if (this.statusByRolesInvendo.length == 0) {
          await this.$store.dispatch("suggestion/getFilterStatusByRolesInvendo", {
            suggestionTypeId: this.suggestionTypeId,
            companyId: this.user.Data.CompanyId,
            loginUserId: this.user.Data.UserId,
          });
        }
        await this.statusByRolesInvendo.forEach(async (element) => {
          this.statusList.push({
            id: element.StatusId,
            value: element.StatusName,
            selected: element.Selected
          });
        });
      } else if (this.suggestionTypeId == "3") {
        if (this.statusByRolesProductization.length == 0) {
          await this.$store.dispatch(
            "suggestion/getFilterStatusByRolesProductization",
            {
              suggestionTypeId: this.suggestionTypeId,
              companyId: this.user.Data.CompanyId,
              loginUserId: this.user.Data.UserId,
            }
          );
        }
        await this.statusByRolesProductization.forEach(async (element) => {
          this.statusList.push({
            id: element.StatusId,
            value: element.StatusName,
            selected: element.Selected
          });
        });
      }
      //this.suggestionStatuses = this.statusList.filter(s => s.selected == true)
    },
    searchFilterRemoveValue() {
      this.equateSelectedCompany();
      this.valueCurrentId = "";
      this.suggestionStatuses = [];
      this.createdUserId = "";
      this.searchKeyword = "";
      this.suggestionNumber = "";
      this.startDate = null;
      this.endDate = null;
      const data = {
        companyId: this.selectedCompany,
        valueCurrentId: this.valueCurrentId,
        suggestionStatuses: this.suggestionStatuses,
        createdUserId: this.createdUserId,
        searchKeyword: this.searchKeyword,
        suggestionNumber: this.suggestionNumber,
        startDate: this.startDate,
        endDate: this.endDate,
        filterReset: true
      };
      this.$emit("clear-search-filter", data);
    },
    searchFilter() {
      let _tmpSuggestionStatusesId = [];
      this.suggestionStatuses.map((i) => _tmpSuggestionStatusesId.push(i.id));
      const createdUserId = this.createdUserId ? this.createdUserId.code : "";

      const data = {
        companyId: this.selectedCompany,
        valueCurrentId: this.valueCurrentId,
        suggestionStatuses: _tmpSuggestionStatusesId,
        createdUserId: createdUserId,
        searchKeyword: this.searchKeyword,
        suggestionNumber: this.suggestionNumber,
        startDate: this.startDate,
        endDate: this.endDate,
      };
      this.$emit("search-filter", data);
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/admin/styles/scss/suggestion-header.scss";
</style>
