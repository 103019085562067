<template>
  <dl class="row px-3 px-sm-5 pt-3 mb-0">
    <dt class="no-colon d-print-none w-xs-100">Aksiyon Seçiniz</dt>
    <dd class="single d-print-none w-xs-100 pl-1 pl-sm-0">
      <div class="form-group">
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            name="idea-action"
            id="cbx-submit"
            data-target="#tab-form-approve"
            value="approve"
            v-model="evaluate"
          />
          <label class="form-check-label" for="cbx-submit">Değerlendir</label>
        </div>

        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            name="idea-action"
            id="cbx-reject"
            data-target="#tab-form-decline"
            value="decline"
            v-model="evaluate"
          />
          <label class="form-check-label" for="cbx-reject">Reddet</label>
        </div>

        <div v-if="evaluate == 'approve'" id="tab-form-approve" style="">
          <form
            action="/Admin/Update/c5dd0e00-a1d9-eb11-bacb-000d3addce7f?updateType=1&amp;status=Distributer"
            id="update-form"
            method="post"
          >
            <div class="form-group icon-folder-alt icon-down">
              <select
                class="form-control"
                id="searchFilterValueCurrent"
                name="ValueCurrent"
                title="Tümü"
                placeholder="Tümü"
                v-model="valueCurrentId"
              >
                <option value="">İlgili Alan Seçiniz</option>
                <optgroup
                  v-for="item in currenValuelist"
                  :key="item.Name"
                  :label="item.Name"
                  :v-model="valueCurrentId"
                >
                  <option
                    v-for="company in item.ValueCurrentDTOs"
                    :key="company.Id"
                    :value="company.Id"
                  >
                    {{ company.Name }}
                  </option>
                </optgroup>
              </select>
            </div>
            <div
              id="drpValueCurrentError"
              class="form-group invalid-feedback"
              :style="[
                requiredField.includes('valueCurrentId')
                  ? { display: 'block' }
                  : '',
              ]"
            >
              Bu alan boş geçilemez!
            </div>
            <div
              v-if="isSuggestionEvaluator"
              class="form-group icon-folder-alt icon-down"
            >
              <select
                class="form-control"
                data-val="true"
                validationelement="1"
                id="drpUser"
                name="SuggestionEvaluator.Id"
                v-model="suggestionEvaluator"
              >
                <option
                  v-for="item in suggestionEvaluatorList"
                  :key="item.Id"
                  :value="item.Id"
                >
                  {{ item.FullName }}
                </option>
              </select>
            </div>
            <div
              v-if="isSuggestionEvaluator"
              id="drpUserError"
              class="form-group invalid-feedback"
              :style="[
                requiredField.includes('suggestionEvaluator')
                  ? { display: 'block' }
                  : '',
              ]"
            >
              Bu alan boş geçilemez!
            </div>
            <div class="form-group">
              <textarea
                class="form-control short"
                cols="20"
                id="textSuggestionExecutiveNote"
                name="SuggestionExecutiveNote"
                placeholder="Fikir Değerlendirme Açıklaması..."
                rows="2"
                validationelement="1"
                v-model="suggestionExecutiveNote"
              ></textarea>
            </div>
            <div
              id="textSuggestionExecutiveNoteError"
              class="form-group invalid-feedback"
              :style="[
                requiredField.includes('suggestionExecutiveNote')
                  ? { display: 'block' }
                  : '',
              ]"
            >
              Bu alan boş geçilemez!
            </div>
            <div class="insider-form-button pb-2 pt-3">
              <button
                type="button"
                class="
                  btn-innovation-radius
                  bg-orange
                  Roboto-Bold
                  py-2
                  px-4
                  mr-3
                "
                data-dismiss="modal"
                aria-label="Close"
                @click="removeValue"
              >
                VAZGEÇ
              </button>
              <button
                :disabled="isBtnDisabled"
                type="button"
                id="btnSendUpdate"
                @click="sendUpdate"
                class="btn-innovation-radius bg-blue-1 Roboto-Bold py-2 px-4"
              >
                GÖNDER
              </button>
            </div>
          </form>
        </div>

        <div v-if="evaluate == 'decline'" id="tab-form-decline" style="">
          <form
            action="/Admin/Update/c5dd0e00-a1d9-eb11-bacb-000d3addce7f?updateType=2&amp;status=Reject"
            method="post"
          >
            <div class="form-group icon-note">
              <select
                class="form-control"
                id="CancelReason"
                name="CancelReason"
                required="required"
                v-model="cancelReason"
              >
                <option
                  v-for="item in getCancelReasonsList"
                  :key="item.Id"
                  :value="item.Id"
                  :disabled="item.Id == ''"
                >
                  {{ item.CancelReasonName }}
                </option>
              </select>
            </div>
            <div
              id="cancelReasonError"
              class="form-group invalid-feedback"
              :style="[
                requiredField.includes('cancelReason')
                  ? { display: 'block' }
                  : '',
              ]"
            >
              Bu alan boş geçilemez!
            </div>
            <div class="form-group">
              <textarea
                class="form-control short"
                cols="20"
                id="txtConfirmNote"
                name="CancelDescription"
                placeholder="Ret Açıklaması..."
                required="required"
                rows="2"
                v-model="cancelDescription"
              ></textarea>
            </div>
            <div
              id="cancelDescriptionError"
              class="form-group invalid-feedback"
              :style="[
                requiredField.includes('cancelDescription')
                  ? { display: 'block' }
                  : '',
              ]"
            >
              Bu alan boş geçilemez!
            </div>
            <div class="insider-form-button pb-2 pt-3">
              <button
                type="button"
                class="
                  btn-innovation-radius
                  bg-orange
                  Roboto-Bold
                  py-2
                  px-4
                  mr-3
                "
                data-dismiss="modal"
                aria-label="Close"
                @click="clearValue"
              >
                VAZGEÇ
              </button>
              <button
                type="button"
                class="btn-innovation-radius bg-blue-1 Roboto-Bold py-2 px-4"
                @click="declineSubmit"
                :disabled="isBtnDisabled"
              >
                GÖNDER
              </button>
            </div>
          </form>
        </div>
      </div>
    </dd>
  </dl>
</template>

<script>
import { mapGetters } from "vuex";
import { suggestionService } from "../../../services";
export default {
  name: "Distributor",
  data() {
    return {
      requiredField: [],
      isBtnDisabled: false,
      evaluate: "",
      suggestionExecutiveNote: null,
      valueCurrentId: 0,
      suggestionSubject: "",
      suggestionEvaluator: "",
      currenValuelist: [],
      suggestionEvaluatorList: [
        { Id: "", FullName: "Fikir Değerlendiriciyi Seçiniz" },
      ],
      cancelReason: "",
      cancelDescription: "",
      getCancelReasonsList: [],
    };
  },
  props: {
    isSuggestionEvaluator: {
      type: Boolean,
      default: true,
    },
    detailData: {
      type: Object,
    },
  },
  watch: {
    async valueCurrentId() {
      await this.getManagerByValueCurrentId();
    },
  },
  computed: {
    ...mapGetters({
      user: "user/user",
    }),
    gettersValueCurrents() {
      return this.$store.getters["suggestion/getValueCurrents"];
    },
    getCancelReasons() {
      return this.$store.getters["suggestion/getCancelReasons"];
    },
  },
  async created() {
    this.valueCurrentId = this.detailData?.ValueCurrentId;
    await this.getValueCurrents();
    if (this.getCancelReasons.length == 0)
      await this.$store.dispatch("suggestion/getCancelReasons", {
        loginUserId: this.user.Data.UserId,
        statusId: this.detailData.SuggestionStatusId,
      });
    this.getCancelReasonsList = [
      { Id: "", CancelReasonName: "Nedeni Seçiniz" },
      ...this.getCancelReasons,
    ];
  },
  methods: {
    async getValueCurrents() {
      if (this.gettersValueCurrents.length == 0) {
        await this.$store.dispatch("suggestion/getValueCurrentsData");
      }
      this.currenValuelist = this.gettersValueCurrents;
    },
    removeValue() {
      this.suggestionExecutiveNote = null;
      this.valueCurrentId = "";
      this.suggestionSubject = "";
      this.suggestionEvaluator = "";
      this.$emit("on-remove-value");
    },
    async sendUpdate() {
      this.requiredField = [];
      if (
        this.suggestionExecutiveNote != null &&
        this.valueCurrentId != "" &&
        ((this.isSuggestionEvaluator && this.suggestionEvaluator != "") ||
          (!this.isSuggestionEvaluator && this.suggestionEvaluator == ""))
      ) {
        this.isBtnDisabled = true;
        this.$emit("send-update", {
          suggestionExecutiveNote: this.suggestionExecutiveNote,
          valueCurrentId: this.valueCurrentId,
          suggestionSubject:
            this.suggestionSubject == "" ? 0 : this.suggestionSubject,
          suggestionEvaluator: this.suggestionEvaluator,
        });
        this.isBtnDisabled = false;
      } else {
        if (this.suggestionExecutiveNote == null)
          this.requiredField.push("suggestionExecutiveNote");
        if (this.valueCurrentId == "")
          this.requiredField.push("valueCurrentId");
        if (this.suggestionEvaluator == "")
          this.requiredField.push("suggestionEvaluator");
      }
    },
    async getManagerByValueCurrentId() {
      const isEvaluator = true;
      const isDecider = false;
      const typeId = 1;
      try {
        const res = await suggestionService.fetchGetManagerByValueCurrentId(
          this.user.Data.CompanyId,
          this.valueCurrentId,
          isEvaluator,
          isDecider,
          typeId
        );
        this.suggestionEvaluatorList = [
          { Id: "", FullName: "Fikir Değerlendiriciyi Seçiniz" },
          ...res.Data,
        ];
      } catch (error) {
        //console.log(error);
      }
    },
    clearValue() {
      this.cancelReason = null;
      this.cancelDescription = null;
      this.$emit("on-clear-value");
    },
    declineSubmit() {
      this.requiredField = [];
      if (this.cancelReason && this.cancelDescription) {
        this.isBtnDisabled = true;
        this.$emit("decline-submit", {
          cancelReason: this.cancelReason,
          cancelDescription: this.cancelDescription,
        });
        this.isBtnDisabled = false;
      } else {
        if (this.cancelReason == "") this.requiredField.push("cancelReason");
        if (this.cancelDescription == "")
          this.requiredField.push("cancelDescription");
      }
    },
  },
};
</script>

<style></style>
