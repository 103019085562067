<template>
  <dl class="row px-3 px-sm-5 pt-3 mb-0">
    <dt class="no-colon d-print-none w-xs-100">Aksiyon Seçiniz</dt>
    <dd class="single d-print-none w-xs-100 pl-1 pl-sm-0">
      <div class="form-group">
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            name="idea-action"
            id="cbx-submit"
            data-target="#tab-form-approve"
            value="approve"
            v-model="evaluatorType"
          />
          <label class="form-check-label" for="cbx-submit">Değerlendir</label>
        </div>

        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            name="idea-action"
            id="cbx-return"
            data-target="#tab-form-return"
            value="return"
            v-model="evaluatorType"
          />
          <label class="form-check-label" for="cbx-return">Geri Gönder</label>
        </div>

        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            name="idea-action"
            id="cbx-reject"
            data-target="#tab-form-decline"
            value="decline"
            v-model="evaluatorType"
          />
          <label class="form-check-label" for="cbx-reject">Reddet</label>
        </div>

        <div id="tab-form-approve" v-if="evaluatorType == 'approve'">
          <form
            action="/Admin/Update/5b677e5f-a0d9-eb11-bacb-000d3addce7f?updateType=1&amp;status=Evaluator"
            id="update-form"
            method="post"
          >
            <!-- <div class="form-group icon-folder-alt icon-down">
              <select
                class="form-control"
                id="searchFilterValueCurrent"
                name="ValueCurrent"
                title="Tümü"
                placeholder="Tümü"
                v-model="valueCurrentId"
              >
                <option value="">İlgili Alan Seçiniz</option>
                <optgroup
                  v-for="item in currenValuelist"
                  :key="item.Name"
                  :label="item.Name"
                >
                  <option
                    v-for="company in item.ValueCurrentDTOs"
                    :key="company.Id"
                    :value="company.Id"
                  >
                    {{ company.Name }}
                  </option>
                </optgroup>
              </select>
            </div>
            <div
              id="drpValueCurrentError"
              class="form-group invalid-feedback"
              :style="[
                requiredField.includes('valueCurrentId')
                  ? { display: 'block' }
                  : '',
              ]"
            >
              Bu alan boş geçilemez!
            </div> -->
            <!-- <div
              v-if="isSuggestionEvaluator"
              class="form-group icon-folder-alt icon-down"
            >
              <select
                class="form-control"
                data-val="true"
                data-val-required="The Id field is required."
                id="drpUser"
                name="SuggestionDecisionMaker.Id"
                validationelement="1"
                v-model="suggestionEvaluator"
              >
                <option
                  v-for="item in suggestionEvaluatorList"
                  :key="item.Id"
                  :value="item.Id"
                >
                  {{ item.FullName }}
                </option>
              </select>
            </div> 
            <div
              v-if="isSuggestionEvaluator"
              id="drpUserError"
              class="form-group invalid-feedback"
              :style="[
                requiredField.includes('suggestionEvaluator')
                  ? { display: 'block' }
                  : '',
              ]"
            >
              Bu alan boş geçilemez!
            </div>
            -->
            <template>
              <div class="form-group">
                <textarea
                  class="form-control short"
                  cols="20"
                  id="SuggestionEvaluatorNote"
                  name="SuggestionEvaluatorNote"
                  placeholder="Fikir Değerlendirme Açıklaması"
                  rows="2"
                  v-model="suggestionEvaluatorNote"
                ></textarea>
              </div>
              <div
                class="form-group invalid-feedback"
                :style="[
                  requiredField.includes('suggestionEvaluatorNote')
                    ? { display: 'block' }
                    : '',
                ]"
              >
                Bu alan boş geçilemez!
              </div>
              <!-- <div class="form-group">
                <textarea
                  class="form-control short"
                  cols="20"
                  id="AboutExpectedBenefit"
                  name="AboutExpectedBenefit"
                  placeholder="Fayda (TL - adam saat/yıl)"
                  rows="2"
                  v-model="aboutExpectedBenefit"
                ></textarea>
              </div> -->
              <!-- <div class="form-group">
                <input
                  class="form-control short"
                  id="ApplicationCost"
                  name="ApplicationCost"
                  placeholder="Uygulama Maliyeti"
                  style="padding-left: 20px !important"
                  type="text"
                  v-model="applicationCost"
                />
              </div> -->
              <!-- <div class="form-group icon-folder-alt icon-down">
                <select
                  class="form-control"
                  id="drpSuggestedApplication"
                  name="SuggestedApplication"
                  validationelement="1"
                  v-model="suggestedApplication"
                >
                  <option value="">Uygulama Önerisini Seçiniz</option>
                  <option value="1">Projelendirilsin</option>
                  <option value="2">Hemen Uygulama</option>
                  <option value="3">Red</option>
                </select>
              </div>
              <div
                id="drpSuggestedApplicationError"
                class="form-group invalid-feedback"
                :style="[
                  requiredField.includes('suggestedApplication')
                    ? { display: 'block' }
                    : '',
                ]"
              >
                Bu alan boş geçilemez!
              </div> -->
            </template>
            <div class="insider-form-button pb-2 pt-3">
              <button
                type="button"
                class="btn-innovation-radius bg-orange Roboto-Bold py-2 px-4 mr-3"
                data-dismiss="modal"
                aria-label="Close"
                @click="removeValue"
              >
                VAZGEÇ
              </button>
              <button
                type="button"
                id="btnSendUpdate"
                class="btn-innovation-radius bg-blue-1 Roboto-Bold py-2 px-4"
                @click="sendUpdate"
                :disabled="isBtnDisabled"
              >
                ONAYLA
              </button>
            </div>
          </form>
        </div>
        <div id="tab-form-return" v-if="evaluatorType == 'return'">
          <form
            action="/Admin/Update/5b677e5f-a0d9-eb11-bacb-000d3addce7f?isReturn=True&amp;status=Distributer"
            method="post"
          >
            <div class="form-group">
              <textarea
                class="form-control"
                cols="20"
                id="ReasonForReturn"
                name="ReasonForReturn"
                placeholder="Geri gönderme nedenini giriniz"
                required="required"
                rows="2"
                v-model="reasonForReturn"
              ></textarea>
            </div>
            <div
              class="form-group invalid-feedback"
              :style="[isReasonForReturn ? { display: 'block' } : '']"
            >
              Bu alan boş geçilemez!
            </div>
            <div class="insider-form-button pb-2 pt-3">
              <button
                type="button"
                class="btn-innovation-radius bg-orange Roboto-Bold py-2 px-4 mr-3"
                data-dismiss="modal"
                aria-label="Close"
                @click="clearReturnValue"
              >
                VAZGEÇ
              </button>
              <button
                type="button"
                class="btn-innovation-radius bg-blue-1 Roboto-Bold py-2 px-4"
                @click="sendReasonForReturn"
                :disabled="isBtnDisabled"
              >
                GÖNDER
              </button>
            </div>
          </form>
        </div>
        <div v-if="evaluatorType == 'decline'" id="tab-form-evaluation-decline">
          <form
            action="/Admin/Update/c5dd0e00-a1d9-eb11-bacb-000d3addce7f?updateType=2&amp;status=Reject"
            method="post"
          >
            <div class="form-group icon-note">
              <select
                class="form-control"
                id="CancelReason"
                name="CancelReason"
                required="required"
                v-model="cancelReason"
              >
                <option
                  v-for="item in getCancelReasonsList"
                  :key="item.Id"
                  :value="item.Id"
                  :disabled="item.Id == ''"
                >
                  {{ item.CancelReasonName }}
                </option>
              </select>
            </div>
            <div
              id="cancelReasonError"
              class="form-group invalid-feedback"
              :style="[
                requiredField.includes('cancelReason')
                  ? { display: 'block' }
                  : '',
              ]"
            >
              Bu alan boş geçilemez!
            </div>
            <div class="form-group">
              <textarea
                class="form-control short"
                cols="20"
                id="txtConfirmNote"
                name="CancelDescription"
                placeholder="Ret Açıklaması..."
                required="required"
                rows="2"
                v-model="cancelDescription"
              ></textarea>
            </div>
            <div
              id="cancelDescriptionError"
              class="form-group invalid-feedback"
              :style="[
                requiredField.includes('cancelDescription')
                  ? { display: 'block' }
                  : '',
              ]"
            >
              Bu alan boş geçilemez!
            </div>
            <div class="insider-form-button pb-2 pt-3">
              <button
                type="button"
                class="btn-innovation-radius bg-orange Roboto-Bold py-2 px-4 mr-3"
                data-dismiss="modal"
                aria-label="Close"
                @click="clearCancelValue"
              >
                VAZGEÇ
              </button>
              <button
                type="button"
                class="btn-innovation-radius bg-blue-1 Roboto-Bold py-2 px-4"
                @click="declineSubmit"
                :disabled="isBtnDisabled"
              >
                GÖNDER
              </button>
            </div>
          </form>
        </div>
      </div>
    </dd>
  </dl>
</template>

<script>
import { mapGetters } from "vuex";
import { suggestionService } from "../../../services";
export default {
  name: "Evaluation",
  data() {
    return {
      requiredField: [],
      evaluatorType: "",
      currenValuelist: [],
      suggestionSubject: "",
      valueCurrentId: "",
      aboutProblem: "",
      aboutSuggestion: "",
      // aboutExpectedBenefit: "",
      // applicationCost: "",
      // suggestedApplication: "",
      // suggestionEvaluator: "",
      suggestedTeam: "",
      workHour: "",
      suggestionEvaluatorNote: "",
      isBtnDisabled: false,
      reasonForReturn: "",
      suggestionEvaluatorList: [],
      cancelReason: "",
      cancelDescription: "",
      getCancelReasonsList: [],
      isReasonForReturn: false,
    };
  },
  watch: {
    // valueCurrentId() {
    //   if (this.valueCurrentId) this.getManagerByValueCurrentId();
    // },
  },
  props: {
    isSuggestionEvaluator: {
      type: Boolean,
      default: true,
    },
    detailData: {
      type: Object,
    },
    typeId: {
      type: Number,
    },
  },
  computed: {
    ...mapGetters({
      user: "user/user",
    }),
    gettersValueCurrents() {
      return this.$store.getters["suggestion/getValueCurrents"];
    },
    getCancelReasons() {
      return this.$store.getters["suggestion/getCancelReasons"];
    },
  },
  async created() {
    this.getValueCurrents();
    this.suggestionSubject = this.detailData.SuggestionSubjectId;
    this.valueCurrentId = this.detailData.ValueCurrentId;
    if (this.getCancelReasons.length == 0)
      await this.$store.dispatch("suggestion/getCancelReasons", {
        loginUserId: this.user.Data.UserId,
        statusId: this.detailData.SuggestionStatusId,
      });
    this.getCancelReasonsList = [
      { Id: "", CancelReasonName: "Nedeni Seçiniz" },
      ...this.getCancelReasons,
    ];
  },
  methods: {
    async getValueCurrents() {
      if (!this.gettersValueCurrents) {
        await this.$store.dispatch("suggestion/getValueCurrentsData");
      }
      this.currenValuelist = this.gettersValueCurrents;
    },
    clearCancelValue() {
      this.cancelReason = null;
      this.cancelDescription = null;
    },
    declineSubmit() {
      this.requiredField = [];
      if (this.cancelReason && this.cancelDescription) {
        this.isBtnDisabled = true;
        this.$emit("decline-submit", {
          cancelReason: this.cancelReason,
          cancelDescription: this.cancelDescription,
        });
        this.isBtnDisabled = false;
      } else {
        if (this.cancelReason == "") this.requiredField.push("cancelReason");
        if (this.cancelDescription == "")
          this.requiredField.push("cancelDescription");
      }
    },
    removeValue() {
      this.suggestionSubject = "";
      this.valueCurrentId = "";
      this.aboutProblem = null;
      this.aboutSuggestion = null;
      // this.aboutExpectedBenefit = null;
      // this.applicationCost = null;
      // this.suggestedApplication = "";
      this.suggestedTeam = null;
      this.workHour = null;
      this.suggestionEvaluatorNote = null;
      // this.suggestionEvaluator = "";
      this.$emit("on-remove-value");
    },
    sendUpdate() {
      this.requiredField = [];
      if (this.suggestionEvaluatorNote != "") {
        this.isBtnDisabled = true;
        const tmpData = {
          suggestionSubject: this.suggestionSubject,
          valueCurrentId: this.valueCurrentId,
          aboutProblem: this.aboutProblem,
          aboutSuggestion: this.aboutSuggestion,
          // aboutExpectedBenefit: this.aboutExpectedBenefit,
          // applicationCost: this.applicationCost,
          // suggestedApplication: this.suggestedApplication,
          // suggestionEvaluator: this.suggestionEvaluator,
          suggestedTeam: this.suggestedTeam,
          workHour: this.workHour,
          suggestionEvaluatorNote: this.suggestionEvaluatorNote,
        };
        // this.suggestionEvaluator
        //   ? (tmpData.suggestionEvaluator = this.suggestionEvaluator)
        //   : "";
        this.$emit("send-update", tmpData);
        this.isBtnDisabled = false;
      } else {
        if (this.suggestionEvaluatorNote == "")
          this.requiredField.push("suggestionEvaluatorNote");
        // if (this.valueCurrentId == "")
        //   this.requiredField.push("valueCurrentId");
      }
    },
    clearReturnValue() {
      this.reasonForReturn = null;
      this.$emit("on-clear-return-value");
    },
    sendReasonForReturn() {
      if (this.reasonForReturn) {
        this.isBtnDisabled = true;
        this.$emit("send-reason-return", {
          reasonForReturn: this.reasonForReturn,
        });
        this.isBtnDisabled = false;
        this.isReasonForReturn = false;
      } else {
        this.isReasonForReturn = true;
      }
    },
    async getManagerByValueCurrentId() {
      const isEvaluator = false;
      const isDecider = true;
      try {
        const res = await suggestionService.fetchGetManagerByValueCurrentId(
          this.user.Data.CompanyId,
          this.valueCurrentId,
          isEvaluator,
          isDecider,
          this.typeId
        );
        this.suggestionEvaluatorList = [
          { Id: "", FullName: "Fikir Karar Vericiyi Seçiniz" },
          ...res.Data,
        ];
      } catch (error) {
        //console.log(error);
      }
    },
  },
};
</script>

<style></style>
